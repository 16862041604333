import styled from "styled-components";
import {Commonpara,Commonh3} from '../Common/common.style'
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {device} from '../Common/device'

export const FeaturesSection = styled.section`
    padding:30px 0px 60px;

    @media ${device.tablet} {
        padding:10px 10px 40px;
    }
    @media ${device.mobileXL} {
        padding:30px 10px 50px;
    }
    @media ${device.mobileM} {
        padding:40px 10px 50px;
    }
`;

export const FeatureCol = styled.div`
`;    

export const CustomRow = styled(Row)`
    align-items:center;

    @media ${device.laptop} {
        flex-direction:column-reverse;
    }
`;    

export const FeatureTxtCol = styled.div`
    margin-bottom:40px;
`;

export const FeatureHeading = styled(Commonh3)`
    text-align:left;
`;

export const FeaturePara = styled(Commonpara)`
`;

export const UlCustom = styled.ul`

`;

export const LiCustom = styled.li`
    color: #484848;
    font-size:18px;
    line-height:22px;
    margin-bottom:10px;
`;

export const FeatureImgCol = styled.div`
`;
