import React from "react";
import {DownloadWrapper,BuyBtn,DownloadHeading} from "./download.style";
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';

const Download =()=>{
    const JSONData  = useStaticQuery(graphql`
        query{
            showcaseJson {
                Download {
                    DelayConstant
                    DownloadHeading
                    BuyBtn
                    BuyBtnHref
                }
            }
        }
    `);
    return (
        <DownloadWrapper id="downloadSection">
            <Container>
                <Row>
                    <Col md="12">
                        <Fade bottom delay={JSONData.showcaseJson.Download.DelayConstant}>
                            <DownloadHeading>
                            {JSONData.showcaseJson.Download.DownloadHeading}
                            </DownloadHeading>
                            <BuyBtn rel="noreferrer" target="_blank" href={JSONData.showcaseJson.Download.BuyBtnHref}>{JSONData.showcaseJson.Download.BuyBtn}</BuyBtn>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </DownloadWrapper>
    );
}
export default Download;