import styled from 'styled-components';
import {Commonh4,Commonpara} from '../Common/common.style';
import {device} from '../Common/device'
import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook'
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter'
import {PinterestAlt} from '@styled-icons/boxicons-logos/PinterestAlt'
import {Behance} from '@styled-icons/boxicons-logos/Behance'

export const FooterWrapper = styled.section`
    width:100%;
    background:#f9fafc;
    padding:100px 0px 0px;

    @media ${device.tablet} {
        padding:80px 10px 0px;
    }
`;

export const FooterCol = styled.div`
    padding:20px 0px;

    ul{
        margin-left:0px;
    }

    a{
        text-decoration:none;
        color:#7a7f83;
        :hover{
            text-decoration:underline;
        }
    }
`;

export const FooterTitle = styled(Commonh4)`
    line-height:1;
    text-align:left;
`;

export const FooterPara = styled(Commonpara)`
`;

export const FooterLi = styled.li`
    list-style:none;
    margin-bottom:6px;

    a{
        color:#484848;
        font-size:15px;
    }
`;

export const FooterSocial = styled.a`
    margin-right:5px;
`;

export const FooterLogo = styled.img`
    width: 200px;
    margin-bottom:20px;
`;

export const BehanceIcon = styled(Behance)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin: 0px 8px;
    background: #191919;
    transition:all .5s;
    
    :hover{
        background: none;
        color: #191919;
        border:2px solid #191919;
    }
`;

export const PinterestIcon = styled(PinterestAlt)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin: 0px 8px;
    background: #bd081c;
    transition:all .5s;
    :hover{
        background: none;
        color: #bd081c;
        border:2px solid #bd081c;
    }
`;
export const TwitterIcon = styled(SocialTwitter)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin: 0px 8px;
    background: #00aeee;
    transition:all .5s;
    :hover{
        background: none;
        color: #00aeee;
        border:2px solid #00aeee;
    }
`;
export const FbIcon = styled(SocialFacebook)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin: 0px 8px;
    background: #3949aa;
    transition:all .5s;
    :hover{
        background: none;
        color: #3949aa;
        border:2px solid #3949aa;
    }
`;

export const BottomFooterWrapper = styled.section`
    width:100%;
    padding:20px 0px;
    background:#fdfdfe;
    text-align: center;
    
    .row{
        align-items: center;
    }

    @media ${device.tablet} {
        text-align:center;
    }
`;
export const BottomFooterPara = styled.p`
    margin-bottom:0px;
    color:#484848;
    padding:10px 0px;   
    font-size:15px;
`;

export const BottomLink = styled.a`
    font-size:15px;  
    margin-bottom:0px;
    padding:10px 0px;     
    color:#484848;
    text-decoration:none;
    :hover{
        text-decoration:underline;
    }
`;

export const BottomFooterLeft = styled.div`
    text-align:left;
`;