import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FooterWrapper,FooterCol,FooterTitle,FooterPara,
    FooterLi,FooterSocial,BottomFooterPara,BottomFooterLeft,
    BottomFooterWrapper,BottomLink,FooterLogo,FbIcon,TwitterIcon,PinterestIcon,
    BehanceIcon} from './footer.style';
import Fade from 'react-reveal/Fade';

import { useStaticQuery, graphql } from 'gatsby';
import ScrollSpy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';


const Footer = () =>{
    const JSONData  = useStaticQuery(graphql`
        query{
            showcaseJson {
                Footer {
                    FooterLogo
                    FooterPara
                    Items
                    FooterCol2{
                        FooterTitle
                        FooterPara{
                            Para
                        }
                    }
                    FooterCol3{
                        FooterTitle
                        FooterPara{
                            Para
                        }
                    }
                    FooterCol4{
                        FooterTitle
                        FooterUl{
                            FooterMenu
                            Href
                        }
                    }
                    BottomFooterPara
                    BottomFooterName
                    BottomFooterLink
                }
            }
        }
    `);
    return (
        <div>
        <FooterWrapper>
            <Container>
                <Fade bottom delay={1*JSONData.showcaseJson.Footer.DelayConstant}>
                <Row>
                    <Col md="4">
                        <FooterCol>
                            <FooterLogo src={JSONData.showcaseJson.Footer.FooterLogo} alt=""/>
                            <BottomFooterLeft>
                                <FooterSocial href="https://www.facebook.com/" rel="noreferrer" aria-label="Facebook Link">
                                    <FbIcon/>
                                </FooterSocial>
                                <FooterSocial href="https://twitter.com" rel="noreferrer" aria-label="Twitter Link">
                                    <TwitterIcon/>
                                </FooterSocial>
                                <FooterSocial href="https://in.pinterest.com" rel="noreferrer" aria-label="Linkedin Link">
                                    <PinterestIcon/>
                                </FooterSocial>
                                <FooterSocial href="https://www.behance.net" rel="noreferrer" aria-label="Instagram Link">
                                    <BehanceIcon/>
                                </FooterSocial>
                            </BottomFooterLeft>
                    
                        </FooterCol>
                    </Col>
                    <Col md="4">
                        <FooterCol>
                            <FooterTitle>{JSONData.showcaseJson.Footer.FooterCol2.FooterTitle}</FooterTitle>
                            {
                                JSONData.showcaseJson.Footer.FooterCol2.FooterPara.map((item,idx) => {
                                return <FooterPara>
                                    {item.Para}
                                    </FooterPara>
                                })
                            }
                        </FooterCol>
                    </Col>
                    <Col md="4">
                        <FooterCol>
                            <FooterTitle>{JSONData.showcaseJson.Footer.FooterCol4.FooterTitle}</FooterTitle>
                            <ScrollSpy offset={-59} items={JSONData.showcaseJson.Footer.Items} currentClassName="is-current">
                            {
                                JSONData.showcaseJson.Footer.FooterCol4.FooterUl.map((item,idx) => {
                                return <FooterLi>
                                    <AnchorLink offset={55} href={item.Href}>
                                        {item.FooterMenu}
                                    </AnchorLink>
                                </FooterLi>
                                })
                            }
                            </ScrollSpy>
                        </FooterCol>
                    </Col>
                </Row>
                </Fade>
            </Container>
        </FooterWrapper>
        <BottomFooterWrapper>
            <Container>
                <Fade bottom delay={2*JSONData.showcaseJson.Footer.DelayConstant}>
                <Row>
                    <Col md="12">
                        <BottomFooterPara>
                            {JSONData.showcaseJson.Footer.BottomFooterPara}
                            <BottomLink rel="noreferrer" href={JSONData.showcaseJson.Footer.BottomFooterLink} target="_blank">
                            {JSONData.showcaseJson.Footer.BottomFooterName}
                            </BottomLink>
                        </BottomFooterPara>
                    </Col>
                </Row>
                </Fade>
            </Container>
        </BottomFooterWrapper>
        </div>
    );
}

export default Footer;