import styled from 'styled-components';
import {Commonh1,Commonh6,Commonbtn} from '../Common/common.style'
import BannerBg from '../../../assets/showcase-images/banner.jpg'

export const BannerWrapper = styled.section`
    background-image: url(${BannerBg});
    min-height:100vh;
    display:flex;
    align-items:center;
    background-repeat:no-repeat;
    background-size:cover;
`;

export const BannerCol = styled.div`
    max-width:700px;
    margin:auto;
    text-align:center;
`;

export const BannerHeading = styled(Commonh1)`
    color:#fff;
`;

export const BannerSubHeading = styled(Commonh6)`
    color:#fff;
    font-weight:400;
`;

export const BannerBtnsWrapper = styled.div`
    
`;

export const BannerBtn = styled(Commonbtn)`
    background: #ff5a00;
    border: none;
    margin-right:10px;

    :hover{
        color:#fff;
        background:#d04c04;
    }

`;

export const BannerBtnDemos = styled(Commonbtn)`
    border: 1px solid #ff5a00;
    color: #ff5a00;
    margin-left:10px;

    :hover{
        color:#fff;
        background:#ff5a00;
    }
`;

export const BannerImg = styled.img`
`;