import React from 'react';
import {FeaturesWrapper,FeaturesRight,FeaturesCard,HeadingWrapper,Heading,
FeaturesCardImg,FeaturesCardHeading,FeaturesCardPara,FeaturesRow
} from './keyfeatures.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const KeyFeatures =()=>{
    const JSONData  = useStaticQuery(graphql`
        query{
            showcaseJson {
                KeyFeatures {
                    DelayConstant
                    Row1{
                        Heading
                    }
                    Row2{
                        FeaturesCardIcon{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        FeaturesCardHeading
                        FeaturesCardPara
                    }
                }
            }
        }
    `);
    return (
            <FeaturesWrapper id="featuresSection">
                <Container>
                    <Row>
                        <Col md="12">
                            <Fade bottom delay={JSONData.showcaseJson.KeyFeatures.DelayConstant}>
                                <HeadingWrapper>
                                    <Heading>{JSONData.showcaseJson.KeyFeatures.Row1.Heading}</Heading>
                                </HeadingWrapper>
                            </Fade>
                        </Col>
                    </Row>
                    <FeaturesRow>
                        <Col md="12" lg="12">
                            <FeaturesRight>
                                <Row>
                                {
                                    JSONData.showcaseJson.KeyFeatures.Row2.map((item,idx) => {
                                    return <Col lg="4" md="6" sm="12">
                                        <Fade bottom delay={JSONData.showcaseJson.KeyFeatures.DelayConstant}>
                                            <FeaturesCard>
                                                <FeaturesCardImg>
                                                    <GatsImg 
                                                        fluid={item.FeaturesCardIcon.childImageSharp.fluid} 
                                                        className="FeaturesCardIcon"
                                                        imgStyle={{
                                                            width:"auto",
                                                            right:"0px",
                                                            margin:"0px auto"
                                                        }}
                                                        alt=""
                                                    />
                                                </FeaturesCardImg>
                                                <FeaturesCardHeading>{item.FeaturesCardHeading}</FeaturesCardHeading>
                                                <FeaturesCardPara>{item.FeaturesCardPara}</FeaturesCardPara>
                                            </FeaturesCard>
                                        </Fade>
                                    </Col>
                                    })
                                }
                                </Row>
                            </FeaturesRight>
                        </Col>
                    </FeaturesRow>
                </Container>
            </FeaturesWrapper>
    );
}
export default KeyFeatures;