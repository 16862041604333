import styled from 'styled-components';
import {device} from '../Common/device'

export const Commonbtn = styled.a`
    color:#fff;
    border:2px solid #fff;
    padding: 10px 50px;
    text-decoration:none;
    border-radius: 4px;
    display: inline-block;
    font-weight:500;
    text-transform:uppercase;
    
    :hover{
        color: #000;
        background:#fff;
        text-decoration:none;
    }
`;

export const Commonh1 = styled.h1`
    font-size: 60px;
    line-height:66px;
    text-align:center;
    margin-bottom:20px;
    color:#353535;

    @media ${device.laptop} {
        font-size: 54px;
        line-height:60px;
    }

    @media ${device.tablet} {
        font-size: 48px;
        line-height:54px;
    }

    @media ${device.mobileXL} {
        font-size: 40px;
        line-height:46px;
    }
`;

export const Commonh2 = styled.h2`
    font-size: 44px;
    line-height:50px;
    text-align:center;
    margin-bottom:20px;
    color:#353535;
`;

export const Commonh3 = styled.h3`
    font-size: 36px;
    line-height:42px;
    margin-bottom: 20px;
    text-align:center;
    color:#353535;  
    
    @media ${device.tablet} {
        font-size: 30px;
        line-height:36px;
    }

`;

export const Commonh4 = styled.h4`
    font-size:30px;
    line-height:36px;
    margin-bottom:20px;
    text-align:center;
    color:#353535;  
`;

export const Commonh5 = styled.h5`
    font-size:24px;
    line-height:30px;
    margin-bottom:20px;
    text-align:center;
    color:#353535;  

    @media ${device.tablet} {
        font-size:22px;
        line-height:28px;
    }
`;

export const Commonh6 = styled.h6`
    font-size:18px;
    line-height:24px;
    margin-bottom:20px;
    text-align:center;
    color:#353535;  
`;

export const Commonpara = styled.p`
    font-size:15px;
    line-height:1.5;    
    color:#484848;
    margin-bottom:20px;
    
`;

export const SectionHeading = styled(Commonh3)`
    line-height:1;
    margin-bottom:10px;
`;

export const SectionSubHeading = styled(Commonpara)`
    line-height:1;
    color:#2b2b2b;
    margin-bottom:0px;
`;




