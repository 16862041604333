import styled from "styled-components";
import {SectionHeading,Commonh5,SectionSubHeading} from '../Common/common.style'
import {device} from '../Common/device'

export const DemoWrapper =styled.section`
    padding:100px 0px 70px;
    background:#f9fafc;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;

export const SectionHeadingWrapper =styled.div`
    text-align:center;
    margin-bottom:30px;
`;

export const Heading =styled(SectionHeading)`
`;

export const SubHeading =styled(SectionSubHeading)`
`;

export const DemoCol =styled.div`
    text-align:center;
    margin: 30px 0px;
    cursor:pointer;
`;

export const DemoImgWrapper =styled.div`
   position:relative;
`;

export const DemoHref =styled.a`
   
`;

export const DemoImgFigure = styled.figure`
    border-radius: 8px;
    margin-bottom:0px;
    height:auto;
    width:100%;
    box-shadow: 0px 0px 15px 1px #dcdcdc;
    overflow: hidden;
    transition:all .3s;

    .DemoColImg{
        border-radius: 8px;
        object-fit:fill;
        height:auto;
        width:100%;
        display:block;
        transition:all .3s;

        :hover{
            transform:scale(1.1);
            transition:all .3s;
        }
    }
`;

export const DemoColHeading =styled(Commonh5)`
    margin-bottom:0px;
    margin-top:15px;
    line-height:1;

    @media ${device.tablet} {
        line-height:1;
    }
`;