import React from "react";
import {FeaturesSection,FeatureCol,CustomRow,FeatureTxtCol,FeatureHeading,FeaturePara,FeatureImgCol,
    UlCustom,LiCustom} from "./features.style";
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const Features =()=>{
    const JSONData  = useStaticQuery(graphql`
    query{
        showcaseJson {
            Features {
                DelayConstant
                Row1{
                    FeatureHeading
                    FeaturePara
                    FeatureImg{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    FeaturesList{
                        feature
                    }
                }
                Row2{
                    FeatureHeading
                    FeaturePara
                    FeatureImg{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`);
    return (
        <FeaturesSection>
            <Container>
                <FeatureCol>
                    <CustomRow>
                        <Col lg="5">
                            <FeatureTxtCol>
                                <Fade bottom delay={JSONData.showcaseJson.Features.DelayConstant}>
                                    <FeatureHeading>
                                    {JSONData.showcaseJson.Features.Row1.FeatureHeading}
                                    </FeatureHeading>
                                    <FeaturePara>
                                    {JSONData.showcaseJson.Features.Row1.FeaturePara}
                                    </FeaturePara>
                                    <UlCustom>
                                        {
                                            JSONData.showcaseJson.Features.Row1.FeaturesList.map((item,idx) => {
                                                return <LiCustom>
                                                    {item.feature}
                                                </LiCustom>
                                            })
                                        }
                                        
                                    </UlCustom>
                                </Fade>
                            </FeatureTxtCol>
                        </Col>
                        <Col lg="7">
                            <Fade bottom delay={JSONData.showcaseJson.Features.DelayConstant}>
                                <FeatureImgCol>
                                    <GatsImg 
                                        fluid={JSONData.showcaseJson.Features.Row1.FeatureImg.childImageSharp.fluid} 
                                        className="FeatureImg"
                                        alt=""
                                    />
                                </FeatureImgCol>
                            </Fade>
                        </Col>
                    </CustomRow>

                    <CustomRow>
                        <Col lg="7">
                            <Fade bottom delay={JSONData.showcaseJson.Features.DelayConstant}>
                                <FeatureImgCol>
                                    <GatsImg 
                                        fluid={JSONData.showcaseJson.Features.Row2.FeatureImg.childImageSharp.fluid} 
                                        className="FeatureImg"
                                        alt=""
                                    />
                                </FeatureImgCol>
                            </Fade>
                        </Col>

                        <Col lg="5">
                            <FeatureTxtCol>
                                <Fade bottom delay={JSONData.showcaseJson.Features.DelayConstant}>
                                    <FeatureHeading>
                                    {JSONData.showcaseJson.Features.Row2.FeatureHeading}
                                    </FeatureHeading>
                                    <FeaturePara>
                                    {JSONData.showcaseJson.Features.Row2.FeaturePara}
                                    </FeaturePara>
                                </Fade>
                            </FeatureTxtCol>
                        </Col>
                    </CustomRow>
                </FeatureCol>
            </Container>
        </FeaturesSection>
    );
}
export default Features;